import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import Debug from 'debug';
import * as fromFeature from '../reducers/twint.coupons.reducer';
import * as fromActions from '../actions/twint.coupons.actions';
import { CouponFilterDTO, CouponListingDTO, CreateCouponDTO, DetailedCouponResponseDTO, UpdateCouponDTO } from '@modeso/types__twint-lib-coupons';
import { IFinancingParty } from '../models/interfaces/finance';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { InvalidateCodeRequest } from '../models/interfaces/invalidate-code-request.interface';
import { InvalidateCodesRequest } from '../models/interfaces/invalidate-codes-request.interface';
import { ArchiveCouponRequest } from '../models/interfaces/archive-coupon-request.interface';

const debug = Debug('twint:coupons:TwintCouponsProvider');

@Injectable({
  providedIn: 'root',
})
export class TwintCouponsProvider {
  constructor(public store: Store<fromFeature.AppState>) { }

  public createCoupon(body: CreateCouponDTO): void {
    this.store.dispatch(fromActions.onAddCoupon({ payload: body }));
  }

  public editCoupon(body: UpdateCouponDTO, couponId: string) : void {
    this.store.dispatch(fromActions.onUpdateCoupon({ payload: { body , couponId }}));
  }

  public getSelectedCoupon$(): Observable<DetailedCouponResponseDTO> {
    return this.store.pipe(select(fromFeature.selectCouponById()));
  }

  public dispatchGetCouponById$(couponId, skip: number, limit: number): void {
    this.store.dispatch(fromActions.fetchCouponDetailsById({payload: { couponId, skip, limit }}));
  }

  public getCoupons$(): Observable<CouponListingDTO[]> {
    return this.store.pipe(select(fromFeature.selectFeatureCoupons));
  }

  public fetchCoupons(data?: CouponFilterDTO): void {
    this.store.dispatch(fromActions.onGetCoupons({ payload: data }));
  }

  public getCouponError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsError));
  }

  public flushError(): any {
    this.store.dispatch(fromActions.flushCouponError());
  }

  public deleteCoupon(couponId: string): void {
    this.store.dispatch(fromActions.onDeleteCoupon({ payload: couponId }));
  }

  public deactivateCoupon(couponId: string): void {
    this.store.dispatch(fromActions.onDeactivateCoupon({ payload: couponId }));
  }

  public activateCoupon(couponId: string): void {
    this.store.dispatch(fromActions.onActivateCoupon({ payload: couponId }));
  }

  public toggleCouponsFeature(couponFeature: boolean): void {
    this.store.dispatch(fromActions.onToggleCouponsFeature({ payload: couponFeature }));
  }

  public checkCouponsFeature(): void {
    this.store.dispatch(fromActions.onCheckCouponsFeature());
  }

  public getCouponsFeature$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsFeature));
  }
  
  public getCouponsFeatureInitializationState$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsInitialized));
  }

  public invalidateCode(invalidateCodePayload: InvalidateCodeRequest): void {
    this.store.dispatch(fromActions.onInvalidateCode({payload: invalidateCodePayload}));
  }

  public invalidateCodes(invalidateCouponCodes: InvalidateCodesRequest): void {
    this.store.dispatch(fromActions.onInvalidateCodes({ payload: invalidateCouponCodes }));
  }

  public archiveCoupon(archiveCoupon:ArchiveCouponRequest ): void {
    this.store.dispatch(fromActions.onArchiveCoupon({ payload: archiveCoupon }));
  }

  public controlCoupon(couponId: string): void {
    this.store.dispatch(fromActions.onControlCoupon({ payload: couponId }));
  }

  // dispatch get financing parties and also create a selector for it
  public dispatchGetFinancingParties(): void {
    this.store.dispatch(fromActions.getFinancingParties());
  }

  public selectFinancingParties(): Observable<IFinancingParty[]> {
    return this.store.pipe(select(fromFeature.selectFinancingParties));
  }

  public addFinanceParty(name: string): void {
    this.store.dispatch(fromActions.addFinanceParty({ payload: name }));
  }

  public exportCostFile(data: ExportCostFileRequest): void {
    this.store.dispatch(fromActions.onExportCostFile({ payload: data }));
  }

  public getSuccessState$(): Observable<boolean> {
    return this.store.pipe(select(fromFeature.selectCouponSuccessState));
  }

  public getExportErrorState$(): Observable<HttpErrorResponse> | undefined {
    return this.store.pipe(select(fromFeature.selectExportCostErrorState));
  }
  public getExportSuccessState$(): Observable<boolean> {
    return this.store.pipe(select(fromFeature.selectExportCostSuccessState));
  }

  public resetExportCostState(): void {
    this.store.dispatch(fromActions.resetExportCostState());
  }

  public dispatchTotalCouponDiscount(couponId: string): void {
    this.store.dispatch(fromActions.onGetCouponTotalDiscount({payload: couponId}));
  }

  public selectTotalCouponDiscount(): Observable<number> {
    return this.store.pipe(select(fromFeature.selectCouponTotalDiscount));
  }
}
