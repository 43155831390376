<div class="container">
  <form class="login-form" [formGroup]="loginForm">
    <div *ngIf="showLoginForm">
      <div class="form-group">
        <div class="input-wrapper">
          <input
            id="userName"
            class="form-control"
            type="text"
            formControlName="userName"
            placeholder="User Name"
          />
        </div>
      </div>
  
      <div class="form-group">
        <div class="input-wrapper">
          <input
            id="password"
            class="form-control"
            type="password"
            formControlName="password"
            placeholder="Password"
          />
        </div>
      </div>
    </div>

    <div class="login-component">
      <button  *ngIf="showLoginForm" class="login-btn" [disabled]="buttonStatus()" (click)="login()">
        <span>Login</span>
      </button>

      <a href="{{loginWithSamlUrl}}" class="login-with-saml">
        <span>Login With SAML</span>
      </a>
    </div>

    <div class="row errorMessages">
      <span class="unauthorized" *ngIf="isLoginFailed"
        >{{loginErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isBadRequest"
        >{{badRequestErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isServerError">{{serverError}}</span>
    </div>

 
  </form>
</div>
